@import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
$default-font: "roboto", "arial", "sans-serif";
$main-color: #404f66;
$background-color: #6e7787;
// $active-color: #c34a2d;
$active-color: tomato;
$text-color: white;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 10px;
  font-family: $default-font;
  text-decoration: none;
  color: $text-color;
  scroll-behavior: smooth;
}
body {
  background-color: $background-color;
}
.wrapper-app {
  display: flex;
  flex-direction: column;
  // align-items: center;
}
.formik {
  margin-bottom: 100px;
  width: 900px;
}
.main-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $main-color;
  position: fixed;
  left: 0;
  top: 10;
  // top: 11vh;
  width: 100%;
  height: 6vh;
  z-index: 10;
  .main-menu-list {
    display: flex;
    list-style: none;
    font-size: 1.6rem;
    .menu-item {
      display: block;
      padding-right: 130px;
      font-weight: bold;
      color: white;
      text-decoration: none;
      position: relative;
      .nav-link::after,
      .nav-link::before {
        transition: 0.9s;
      }
    }

    .menu-item::after {
      position: absolute;
      left: 0;
      bottom: -4px;
      content: "";
      height: 3px;
      width: 100%;
      background-color: $active-color;
      transform: scaleX(0);
      transition: transform 0.7s;
      transform-origin: right;
    }

    .menu-item:hover::after {
      transform: scaleX(1);
      transform-origin: left;
    }
    .menu-item:hover {
      color: $active-color;
    }
    .is-active {
      color: $active-color;
    }
  }
  img {
    height: 50px;
    margin-right: 50px;
  }
  // .active {
  //   color: $active-color;
  // }
  // .is-current  {
  //   // background-color: #c34a2d;
  //   color: $active-color;

  //   // font-size: 5rem;
  // }
}
/* Header ---------------------------- */
.top-hero {
  position: relative;
  height: 350px;
  /* nowy header */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60vh;

  background-position: center;
  background-size: cover;
  background-attachment: scroll;
  z-index: 0;
}
.info-hero {
  // background-image: url("../images/lightHouse.jpg");
  background-image: url("../images/mac3.jpg");
  // background-position: 0 40%;
}
.white-block {
  position: absolute;
  height: 50px;
  width: 40%;
  background-color: $background-color;

  &-left {
    bottom: 0;
    left: -50px;
    transform: skew(60deg);
  }

  &-right {
    top: 0;
    right: -50px;
    transform: skew(45deg);
  }
}
// .hero-shadow {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//  // background-color:  rgba(20, 105, 241, 0.48);
//   z-index: -1;
// }
/* About RockStar ---------------------------- */
.about-rockstar {
  // position: relative;
  // left: 0;
  // top: 7vh;
  img {
    margin-left: 280px;
    width: 170px;
  }
  .visitor-info {
    color: $text-color;
    margin: 0px 14vw;
    h1 {
      font-size: 2.5rem;
      margin-bottom: 25px;
      span {
        color: $active-color;
      }
    }
    p {
      font-size: 1.6rem;
      line-height: 2.7rem;
      text-align: justify;
    }
    .ideal {
      color: $active-color;
      font-size: 2rem;
      margin: 30px 0;
    }
  }

  .describe {
    margin-top: 30px;
    color: $active-color;
    display: flex;
    justify-content: space-between;
  }
  .describe span {
    font-size: 2.1rem;
  }
  .describe span i {
    padding: 0 10px;
  }
  .apartment-details-list,
  .what-included-list,
  .nearest-list,
  .anything-else-list {
    list-style: none;
    font-size: 2rem;
  }
  .fa-check-circle {
    padding-right: 10px;
  }

  .apartment-details h2,
  .what-included h2,
  .nearest h2,
  .anything-else h2,
  .location h2 {
    margin: 30px 0;
    font-size: 2.5rem;
  }
  .apartment-details ul li,
  .what-included ul li,
  .nearest ul li,
  .anything-else ul li {
    line-height: 4rem;
  }
  .apartment-details h2 span {
    color: $active-color;
  }
  .what-included span {
    color: $active-color;
  }
  .anything-else span {
    color: $active-color;
  }
}
/* Location ---------------------------- */
.location {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  margin: 50px 14vw;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  //   .location {
  //     display: flex;
  //     flex-wrap: wrap;
  //   }

  .welcome {
    display: flex;
    h2 {
      flex-grow: 9;
      flex-wrap: wrap;
      //   flex-basis: 100%;
    }
    img {
      height: 180px;
    }
  }
  p {
    margin-left: 20px;
    font-size: 1.6rem;
    line-height: 2.7rem;
    text-align: justify;
  }
  .map iframe {
    width: 100%;
    height: 50vh;
    margin: 40px 0;
  }
}
/* Gallery ---------------------------- */
.gallery {
  width: 61%;
  // height: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
  position: relative;
  // .image-gallery {
  //   height: 50vh;
  // }
  h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    margin-right: 250px;
    span {
      color: $active-color;
    }
  }

  .side-title {
    position: absolute;
    top: 80%;
    left: -12%;
    font-size: 2.3rem;
    transform-origin: 0 0;
    transform: rotate(-90deg);
    //  margin-right: 200px;
    // margin-bottom: 50px;
    span {
      color: $active-color;
    }
  }
}
/* Reviews ------------------*/
.reviews {
  margin: 30px 14vw;
  color: $text-color;
  /* z-index: -2; */
  // margin-bottom: 300px;
  h1 {
    font-size: 2.5rem;
    // flex-basis: 100%;
    margin-bottom: 30px;
  }
}

.reviews-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* position: fixed; */
  height: 71vh;
  //  overflow: hidden;
  overflow: auto;
  /* z-index: -2; */
}

.reviews-box {
  border: 2px solid $main-color;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgb(74, 74, 74);
  /* box-shadow: 5px 5px 10px rgba(46, 46, 46, 0.18); */
  width: 55vw;
  height: 200px;
  font-size: 1.6rem;
  //  overflow: hidden;
  margin: 30px 0;
  position: relative;
  transition: transform 0.3s;
  /* z-index: -1;  */
}
.reviews-box:hover {
  transform: scale(0.95);
  background-color: white;
  color: $main-color;
}

.reviews-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70%;

  /* padding: 10px;  */
}
.reviews-top p {
  padding: 15px 35px 10px 35px;
}
.fa-star {
  position: absolute;
  top: -10px;
  left: -10px;
  color: $active-color;
  font-size: 2.5rem;
  /* transform: translate(-30%, -30%); */
}
.reviews-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  height: 30%;
  background-color: $main-color;
  color: $text-color;
}
.home-box-up {
  position: relative;
  position: fixed;
  bottom: 240px;
  right: 0px;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  font-size: 25px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: border-radius 0.4s;
  display: none;
  z-index: 101;
}
.home-box-up:hover {
  /* background-color: rgb(200, 124, 10); */
  border-radius: 50%;
}
.fa-angle-up {
  position: absolute;
  color: whitesmoke;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.contact {
  display: flex;
  flex-direction: column;

  // align-items: center;
  margin: 0 14vw;
  margin-top: 82px;
  margin-bottom: 170px;
  // background-color: #c34a2d;
  .contact-wrapper {
    display: flex;
    flex-wrap: wrap;
    .contact-title {
      width: 100vw;
      h1 {
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 30px;
        span {
          color: $active-color;
        }
      }
      p {
        font-size: 1.6rem;
        text-align: center;
        margin-bottom: 50px;
      }
    }
  }

  .property-address {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    margin-left: 50px;
    border: 1px solid $main-color;
    padding: 10px 15px;
    i {
      padding-right: 5px;
    }
    a {
      color: $text-color;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
    p {
      line-height: 3.7rem;
    }
    p:first-child {
      color: $active-color;
      font-size: 2rem;
    }
    p:nth-child(5) {
      color: $active-color;
      font-size: 2rem;
    }
    img {
      margin-top: 60px;
      width: 200px;
    }
  }
  // width: 55vw;
  .contact-form {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 30px auto;
    font-size: 2rem;

    .top-form-contact {
      display: flex;
      .name-top,
      .email-top {
        display: flex;
        flex-direction: column;
        width: 50%;
      }
      .email-top {
        margin-left: 20px;
      }
    }
    input,
    textarea {
      padding: 10px;
      color: $text-color;
      margin-bottom: 40px;
      border-radius: 7px;
      background-color: $background-color;
      // border: none;
      border: 2px solid $main-color;
      outline: 2px solid transparent;
    }
    input:focus,
    textarea:focus {
      border: 2px solid $active-color;
      background-color: white;
      color: $main-color;
    }
    label {
      margin-bottom: 10px;
    }
    button {
      padding: 15px;
      width: 25%;
      border: none;
      font-size: 1.6rem;
      color: $text-color;
      text-transform: uppercase;
      border-radius: 7px;
      background-color: $active-color;
      cursor: pointer;
      transition: 0.5s;
      margin-top: 20px;
    }
    button:hover {
      background-color: $main-color;
    }
  }
  textarea {
    resize: none;
  }
  form .honey-row {
    display: none;
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  left: 0;
  font-size: 1.6rem;
  font-weight: bold;
  height: 5vh;
  width: 100%;
  color: white;
  background-color: $main-color;
  z-index: 10;
}

/////// Burger Menu

.burger {
  // position: fixed;
  // top: 5px;
  // right: 30px;

  margin-right: 20px;
  font-size: 35px;
  color: white;
  z-index: 1;
  cursor: pointer;
  display: none;
}
.burger:hover {
  color: $active-color;
}

/**************** Responsywność */
// @media (min-width: 0px) and (max-width: 359px) {
//   .main-menu {
//     // display: none;
//     // top: -48vh;
//     height: 100vh;
//     .main-menu-list {

//       .menu-item{
//         font-size: 1.5rem;
//       }
//     }
//   }
// }
@media (max-width: 992px) {
  .top-burger-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $main-color;
    position: fixed;
    left: 0;
    top: 10;

    // top: 11vh;
    width: 100%;
    height: 7vh;
    z-index: 11;
    h1 {
      text-transform: uppercase;
      margin-left: 40px;
      span {
        color: $active-color;
      }
    }
  }
  .main-menu {
    // display: none;
    top: -60vh;
    height: 65vh;
    border-top: 3px solid black;
    transition: 0.5s;
    // background: rgba(64, 79,102, 0.9);

    .main-menu-list {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 2.5rem;
    }
    .menu-item {
      margin-bottom: 15px;
      padding-right: 0px;
    }
    img {
      height: 0px;
    }
  }
  .info-hero {
    background-image: url("../images/mac4.jpg");
  }
  .about-rockstar {
    .visitor-info {
      margin: 0px 15px;
      h1 {
        margin-top: 38px;
      }
      p {
        text-align: justify;
      }
    }
    .describe {
      display: flex;
      flex-direction: column;
      span {
        margin-bottom: 20px;
      }
    }
  }
  .location {
    margin: 0px auto;
    h1 {
      margin-top: 0px;
      margin-left: 20px;
    }
    .welcome {
      display: flex;
      flex-direction: column;
      p {
        text-align: justify;
        margin-right: 15px;
      }
      img {
        // height: 140px;
        width: 320px;
        margin: 0px auto;
        margin-bottom: 15px;
      }
    }
  }
  .gallery {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    margin-bottom: 40px;
    h1 {
      margin-bottom: 30px;
    }
    // .image-gallery {
    //   height: 50vh;
    // }
    .side-title {
      display: none;
    }
  }
  .reviews {
    margin: 0px auto;
    h1 {
      margin-left: 20px;
    }
    .reviews-box {
      width: 100%;
      margin: 10px 10px;

      .reviews-top {
        font-size: 1.4rem;
        .long-comment {
          font-size: 1.2rem;
        }
        p {
          padding: 15px 20px 10px 20px;
        }
      }
      .reviews-bottom {
        padding: 0 10px;
        font-size: 1.2rem;
      }
    }
  }
  .contact {
    margin-bottom: 50px;
    .property-address {
      font-size: 1.6rem;
      margin: 0px auto;
      padding: 10px 40px;
    }
    .contact-form {
      width: 100%;

      .top-form-contact {
        display: flex;
        flex-direction: column;
        .name-top,
        .email-top {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 0px;
        }
      }
      button {
        // padding: 15px;
        width: 100%;
      }
    }
  }

  .footer {
    font-size: 1.2rem;
  }

  .burger {
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-menu {
    .main-menu-list {
      .menu-item {
        padding-right: 50px;
      }
    }
  }
  .about-rockstar {
    .visitor-info {
      margin: 0px 5vw;
      h1 {
        margin-top: 25px;
      }
    }
  }
  .location {
    margin: 0px 5vw;
    .welcome {
      img {
        height: 130px;
      }
    }
  }
  .gallery {
    width: 70%;
    .side-title {
      left: -16%;
    }
  }
  .reviews {
    .reviews-box {
      width: 70vw;
    }
  }
  .contact {
    margin: 80px 5vw;
    margin-bottom: 110px;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .gallery {
    .side-title {
      left: -17%;
    }
  }
}
.active-top-menu {
  display: flex;
  top: 0;
}
